<template>
  <div class="list-wrapper">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="item in classify" :key="item" :label="item" :name="item"/>
    </el-tabs>
    <!--    <div style="display: flex;margin-bottom:10px;">-->
    <!--      <a-button type="primary" @click="addTemp">增加模板</a-button>-->
    <!--    </div>-->
    <div class="list-box">
      <el-card v-for="item in tempList" :key="item.id" :body-style="{ padding: '0px' , width:'300px' ,height:'300px'}">
        <el-image :src="item.thumbPic" :preview-src-list="[item.thumbPic]"/>
        <!-- <img :src="item.thumbPic" class="image"> -->
        <div style="padding: 14px;">
          <span>{{ item.modelName }}</span>
          <div class="bottom clearfix">
            <time class="time">{{ item.createTime }}</time>
            <el-button type="text" class="button" @click="deleteTemplate(item.id)">删除
            </el-button>
            <el-button type="text" class="button" @click="updateTemp(item)">修改</el-button>
          </div>
        </div>
      </el-card>
    </div>
    <div class="pagination-box">
      <el-pagination class="fl" background layout="prev, pager, next" :total="totalRow" :current-page.sync="pageNo"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  components: {},
  props: {},
  data() {
    return {
      pageNo: 1,
      pageSize: 8,
      modelName: '',
      tempList: [],
      totalRow: 0,
      classify: ['全部模板'],
      activeName: '全部模板',
      curClassify: ''
    }
  },
  computed: {
    // ...mapGetters(['currentUser'])
  },
  watch: {
    pageNo() {
      this.getTemplate()
    },
    curClassify() {
      this.getTemplate()
    }
  },
  created() {
    this.getTemplate()
    this.getClassifyForDiy()
  },
  activated() {
    this.getTemplate()
  },
  mounted() {
  },
  methods: {
    handleClick(value) {
      this.curClassify = value.name
      this.activeName = value.name
      if (value.name === '全部模板') {
        this.curClassify = ''
      }
    },
    getTemplate() {
      const {pageNo, pageSize, modelName, curClassify} = this
      this.$postJson('diy/list', {
        pageNo,
        pageSize,
        modelName,
        classify: curClassify
      }).then((result) => {
        this.tempList = result.data
        this.totalRow = result.total
      }).catch((err) => {
        console.log(err)
      })
    },
    getClassifyForDiy() {
      this.$post("diy/getClassify").then(res => {
        this.classify = this.classify.concat(res.data[0].value.split(','))
      })
    },
    deleteTemplate(id) {
      const _this = this
      this.$confirm({
        title: "删除确认",
        content: "此操作将永久删除该模板, 是否继续?",
        centered: true,
        onOk() {
          _this.$post("diy/delete", {id}).then(res => {
            _this.$message.success('删除成功!')
            _this.getTemplate()
          })
        },
      });
    },
    updateTemp(item) {
      this.$router.push({path: '/diy/edit', query: {id: item.id}})
    },
    pageChange(pageNo) {
      this.pageNo = pageNo
    },
    addTemp() {
      this.$router.push({path: '/post/diy/add'})
    }
  }
}
</script>
<style lang="less" scoped>
.fl {
  float: right;
}

.list-wrapper {
  width: 100%;

  .pagination-box {
    height: 50px;
    padding-top: 10px;
  }

  .list-box {
    //height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;

    .el-card {
      margin: 0px 10px 20px 10px !important;
    }

    .time {
      font-size: 13px;
      color: #999;
    }

    .bottom {
      margin-top: 13px;
      line-height: 12px;
    }

    .button {
      margin-left: 10px;
      padding: 0;
      float: right;
    }

    .image {
      width: 100%;
      height: 192px;
      display: block;
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }

    .clearfix:after {
      clear: both;
    }
  }
}
</style>
